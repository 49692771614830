export default [
  {
    path: '/loyalty-members',
    name: 'members-list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/members/MemberList.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin'],
    },
  },
  {
    path: '/complaints',
    name: 'complaints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/members/Complaints.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin'],
    },
  },
  {
    path: '/users',
    name: 'users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/users/UsersList.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['s_admin'],
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/users/MyAccount.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin', 'account'],
    },
  },
];
