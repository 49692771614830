import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#65B035',
        secondary: '#015F72',
        success: '#3CC13B',
        error: '#DC3055',
        info: '#2196F3',
        warning: '#FD8E39',

        blackvariant1: '#2D2D2D',

        greyvariant1: '#919191',
        greyvariant2: '#B0BEC5',
        greyvariant3: '#CFD8DC',
        greyvariant4: '#FAFAFA',
        greyvariant5: '#F7F7F7',
        greyvariant6: '#f4f4f4',
        bluevariant1: '#90D9F8',
        bluevariant2: '#DBEDF6',

        redvariant1: '#FFD7DF',

        orangevariant1: '#FDD0AD',
      },
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
});
