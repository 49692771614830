export default {
  SET_USER: (state, user) => {
    state.user = {
      ...user,
    };
  },
  SET_FORGOT_PASSWORD_EMAIL: (state, email) => {
    state.forgotPasswordEmail = email;
  },
};
