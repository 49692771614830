<template>
  <v-app>
    <NavDrawerAppBar v-if="navShow" />
    <v-overlay absolute :value="overlay" z-index="500">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-if="message"
      :value="message"
      :color="message ? message.color : ''"
      multi-line
      @input="handleSnackbarClose"
    >
      <v-icon
        :color="message && message.type == 'success' ? 'primary' : 'error'"
        >{{
          message && message.type == 'success'
            ? 'mdi-check-circle'
            : 'mdi-close-circle'
        }}
      </v-icon>
      <span class="caption ml-2">{{ message ? message.message : '' }}</span>

      <template v-slot:action="{ attrs }">
        <v-icon @click="closeMessage" v-bind="attrs">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-main class="greyvariant5">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import NavDrawerAppBar from '@/components/common/NavigationDrawerAppBar.vue';
import authService from './services/auth-service';

export default {
  name: 'App',

  components: { NavDrawerAppBar },
  data: () => ({
    drawer: false,
    group: null,
    timeout: 2000,
    snackbar: true,
    navShow: false,
  }),

  computed: {
    overlay() {
      return this.$store.state.common.pendingRequestsCount > 0;
    },
    message() {
      return this.$store.state.common.notification;
    },
    locale() {
      return this.$store.state.common.locale;
    },
    localAttrs() {
      const attrs = {};
      attrs.absolute = true;

      return attrs;
    },
    nav() {
      return [
        'login',
        'register',
        'forgot-password',
        'reset-password',
      ].includes(this.$route.name);
    },
  },
  watch: {
    $route(to) {
      this.navShow = [
        'login',
        'register',
        'forgot-password',
        'reset-password',
      ].includes(to.name)
        ? false
        : true;
    },
  },
  methods: {
    closeMessage() {
      this.$store.commit('common/REMOVE_NOTIFICATION');
    },
    handleSnackbarClose(input) {
      if (!input) {
        this.$store.commit('common/REMOVE_NOTIFICATION');
      }
    },
  },
  mounted() {
    authService
      .getUser()
      .then(() => {
        if (
          this.$store.getters['auth/isLoggedIn'] &&
          this.$route.meta.publicPage
        ) {
          this.$router.push({ name: 'home' });
        }
      })
      .catch(() => {});
  },
};
</script>
