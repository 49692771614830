<template>
  <div>
    <v-app-bar
      color="primary"
      permanent
      absolute
      app
      height="58px"
      elevation="1"
    >
      <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>

      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="secondary" size="48">
              <span class="white--text text-h5">{{
                user.name.match(/\b(\w)/g).join('')
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="myAccount">
            <v-list-item-title>My Account</v-list-item-title>
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      absolute
      app
    >
      <v-list-item class="mt-8 mb-10" :class="!mini ? 'logo-item' : ''">
        <v-img
          v-if="!mini"
          contain
          :src="require('../../assets/alumex-logo.svg')"
        ></v-img>
        <v-img
          contain
          v-else
          :src="require('../../assets/png/alumex-logo-short.png')"
          class="img-logo"
        ></v-img>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          class="mt-2"
          :to="item.url"
          v-show="
            $router
              .resolve({ path: item.url })
              .route.meta.adminType.includes($store.state.auth.user.admin_type)
          "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import AuthService from '../../services/auth-service';
import { mapGetters } from 'vuex';
export default {
  components: {},
  data: () => ({
    drawer: true,
    items: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        url: '/',
        disable: false,
      },
      {
        title: 'Loyalty Members',
        icon: 'mdi-crown',
        url: '/loyalty-members',
        disable: false,
      },
      {
        title: 'Feed Points',
        icon: 'mdi-vector-point-plus',
        url: '/feed-points',
        disable: false,
      },
      // {
      //   title: 'Loyalty Requests',
      //   icon: 'mdi-wallet-giftcard',
      //   url: '/loyalty-requests',
      //   disable: true,
      // },
      {
        title: 'Redeem Requests',
        icon: 'mdi-gift',
        url: '/redeem-requests',
        disable: false,
      },
      {
        title: 'Messages',
        icon: 'mdi-message-reply-text-outline',
        url: '/complaints',
        disable: false,
      },
      {
        title: 'Executives',
        icon: 'mdi-face-agent',
        url: '/executives',
        disable: false,
      },
      // {
      //   title: 'Stock Transfer',
      //   icon: 'mdi-briefcase-upload',
      //   url: '/stock-transfer',
      //   disable: true,
      // },

      {
        title: 'Users',
        icon: 'mdi-account-multiple',
        url: '/users',
        disable: false,
      },
      {
        title: 'Settings',
        icon: 'mdi-cog',
        url: '/settings',
        disable: false,
      },
    ],
    mini: true,
    group: null,
  }),
  methods: {
    myAccount() {
      this.$router.push('/my-account');
    },
    logout() {
      AuthService.logout();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    navButtons() {
      return ['login', 'register', 'forgot-password'].includes(
        this.$route.name
      );
    },
  },
};
</script>
<style scoped>
.logo-item {
  min-height: 230px;
}
.img-logo {
  transition-timing-function: linear;
}
</style>
