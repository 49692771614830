import router from '@/router';
import notificationsUtility from '@/utilities/notifications-utility';
import axios from 'axios';
import store from '@/store';
import i18n from '@/plugins/i18n';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

HTTP.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      Accept: 'application/json',
      'Accept-Language': i18n.locale,
    };
    store.commit('common/ADD_REQUEST');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    store.commit('common/REMOVE_REQUEST');
    if (response.config.method != 'get' && response.data.message.length > 0) {
      notificationsUtility.pushNotification({
        message: response.data.message,
        type: 'success',
      });
    }
    return response.data;
  },
  (error) => {
    console.log('error', error);
    store.commit('common/REMOVE_REQUEST');
    if (error.response.status === 422) {
      const errorKey = Object.keys(error.response.data.data)[0];
      notificationsUtility.pushNotification({
        message: error.response.data.data[errorKey][0],
        type: 'error',
      });
    } else if (error.response.status === 401) {
      const isLoggedIn = localStorage.getItem('token');
      if (isLoggedIn) {
        store.dispatch('auth/logout');
        notificationsUtility.pushNotification({
          message: i18n.t('messages.unauthorized'),
          type: 'error',
        });
      }
      if (
        router.currentRoute.name !== 'login' &&
        !router.currentRoute.meta.publicPage
      ) {
        router.push({ name: 'login' });
      }
    } else if (error.response.data.message.length > 0) {
      let message = null;
      if (error.response.data instanceof ArrayBuffer) {
        message = JSON.parse(
          new TextDecoder('utf-8').decode(error.response.data)
        ).message;
      } else {
        message = error.response.data.message;
      }
      notificationsUtility.pushNotification({
        message: message,
        type: 'error',
      });
    }
    return Promise.reject(error);
  }
);

export default HTTP;
