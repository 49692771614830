import en from '@/locales/en';
import es from '@/locales/es';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const locale = localStorage.getItem('locale');

export default new VueI18n({
  locale: locale || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: en,
    es: es,
  },
});
