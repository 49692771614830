export default {
  SET_NOTIFICATION: (state, notification) => {
    state.notification = notification;
  },
  REMOVE_NOTIFICATION: (state) => {
    state.notification = null;
  },
  ADD_REQUEST: (state) => {
    state.pendingRequestsCount += 1;
  },
  REMOVE_REQUEST: (state) => {
    state.pendingRequestsCount =
      state.pendingRequestsCount > 0 ? state.pendingRequestsCount - 1 : 0;
  },
  CHANGE_LOCALE: (state, locale) => {
    state.locale = locale;
  },
  TOGGLE_FILTER_AREA: (state) => {
    state.filterAreaVisible = !state.filterAreaVisible;
  },
  SHOW_APP_DRAWER: (state, visibility) => {
    state.appDrawerVisible = visibility;
  },
  TOGGLE_APP_SIDEBAR_NAV: (state) => {
    state.appSideNavigationVisible = !state.appSideNavigationVisible;
  },
  SHOW_APP_SIDEBAR_NAV: (state, visibility) => {
    state.appSideNavigationVisible = visibility;
  },
  TOGGLE_APP_SIDEBAR_NAV_TRIGGER_STATE: (state) => {
    state.appSideNavigationManuallyTriggered =
      !state.appSideNavigationManuallyTriggered;
  },
};
