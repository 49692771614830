import inputs from "./inputs.json";
import validations from "./validations.json";
import buttons from "./buttons.json";
import texts from "./texts.json";
import messages from "./messages.json";

export default {
  inputs, // Text related to form controls
  validations, // Text related to form control validations
  buttons, // Text related to buttons
  texts, // All other texts
  messages, // Text related to toastrs
};
