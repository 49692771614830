import i18n from '@/plugins/i18n';
import moment from 'moment';

// const emailRegex = /^[^@]+@\w+(\.\w+)+\w$/;
// const mobile =
//   /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

const mobile = /^[0]{1}[7]{1}[01245678]{1}[0-9]{7}$/;

export default {
  email: (v) =>
    // eslint-disable-next-line no-useless-escape
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    !v ||
    i18n.t('validations.email'),
  required: (v) =>
    (v instanceof Array ? v.length > 0 : !!v) || i18n.t('validations.required'),
  radioRequired: (v) => v !== null || i18n.t('validations.required'),
  verificationCodeRules: (v) =>
    (v && v.length == 6) || i18n.t('validations.verificationCode'),
  passwordRules: (v) => (v && v.length >= 8) || i18n.t('validations.password'),
  confirmPasswordRules: (value) => (v) =>
    v === value || !v || i18n.t('validations.passwordConfirmation'),
  imageSizeLimit: (v) =>
    !v || v.size < 2000000 || i18n.t('validations.iamgeSize'),
  futureDateRule: (value) => (v) =>
    !v ||
    !value ||
    moment(v).isAfter(moment(value)) ||
    i18n.t('validations.futureDate'),
  time: (v) => (v > 0 && v < 20) || i18n.t('validations.time'),
  validateDescription: (v) =>
    (v && v.length < 250) || i18n.t('validations.validateDescription'),
  equalTenCharacters: (v) =>
    mobile.test(v) || !v || i18n.t('validations.mobileNumber'),
  validateNumber: (v) =>
    Number.isInteger(Number(v)) || i18n.t('validations.number'),
  fileSize: (v) =>
    !v || v.size < 4000000 || 'File size should be less than 4 MB',
  fileTypeImages: (v) =>
    !v ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'jpg' ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'png' ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'jpeg' ||
    'File type should be jpg, jpeg, png',
  fileTypeDocument: (v) =>
    !v ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'jpg' ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'png' ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'jpeg' ||
    v.name.substr(v.name.lastIndexOf('.') + 1) === 'pdf' ||
    'File type should be jpg, jpeg, png, pdf',
  lengthCheck: (len) => (v) =>
    (v || '').length < len || `Invalid character length, maximum ${len}`,
};
