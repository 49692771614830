// import { adminTypes } from '@/utilities/constants';
export default [
  {
    path: '/redeem-requests',
    name: 'redeem-requests',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/loyalty/RedeemRequests.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['s_admin', 'accounts'],
    },
  },
  {
    path: '/feed-points',
    name: 'feed-points',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/loyalty/FeedPoints.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin'],
    },
  },
];
