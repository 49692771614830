export default {
  SET_POINTS: (state, item) => {
    state.points[item.slug] = {
      count: state.points[item.slug] ? state.points[item.slug].count + 1 : 1,
      value:
        item.points *
        (state.points[item.slug] ? state.points[item.slug].count + 1 : 1),
    };
  },
};
